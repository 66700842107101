import React from 'react';

import Layout from '../components/Layout';

import pic1 from '../assets/images/resume.png';

const IndexPage = () => (
  <Layout>
    <article id="main">
      <header>
        <h2>Resume</h2>
        <p>Super snazzy hook to get them to download the resume</p>
      </header>
      <section className="wrapper style5">
        <div className="resume__box">
          <img src={pic1} alt="resume" className="resume" />
        </div>
      </section>
    </article>
  </Layout>
);

export default IndexPage;
